import React, { useCallback, useState } from "react";
import ReactSimplyCarousel from "react-simply-carousel";
import RenderMedia from "./RenderMedia";
import { isVideo } from "../../../utils";

const EventImageCarousel = ({ item }: any) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [load, setLoad] = useState(false);
  const changeSlide = useCallback(
    (index) => {
      setLoad(true);
      setActiveSlideIndex(index);
    },
    [setLoad, setActiveSlideIndex]
  );

  if (!item) return null;
  const {
    photos = [],
    media = { uploadDocumentThumbnail: "", uploadDocument: "" },
    id,
  } = item;
  const hasMultipleMedia = photos?.length > 1;

  return (
    <div className="carousal-item-custom">
      {hasMultipleMedia ? (
        <ReactSimplyCarousel
          activeSlideIndex={activeSlideIndex}
          onRequestChange={changeSlide}
          itemsToShow={1}
          itemsToScroll={1}
          containerProps={{
            style:{
              maxHeight:'320px',
              borderRadius: '12px',
              overflow: 'hidden',
            }
          }}
          dotsNav={{
            show: true,
            containerProps: {
              className: "dots-nav-container",
              style: {
                position: "absolute",
                bottom: "10%",
                gap: "6px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              },
            },
            itemBtnProps: {
              style: {
                height: 6,
                width: 6,
                borderRadius: "50%",
                border: 0,
                background: "rgba(255, 255, 255, 0.4)",
              },
            },
            activeItemBtnProps: {
              style: {
                height: 7,
                width: 7,
                borderRadius: "60%",
                border: 0,
                background: "white",
              },
            },
          }}
          forwardBtnProps={{
            className: "image-carousel-button",
            style: {
              background: "rgba(255, 255, 255, 0.4)",
              borderRadius: "50%",
              border: "none",
              cursor: "pointer",
              height: 24,
              width: 24,
              position: "absolute",
              zIndex: "0",
              top: "50%",
              right: "16px",
              translate: "0 -50%",

              justifyContent: "center",
              alignItems: "center",
            },
            children: (
              <img
                style={{ width: "16px", height: "16px" }}
                alt="Forward"
                src="/static/chevron-black-right.svg"
              />
            ),
          }}
          backwardBtnProps={{
            className: "image-carousel-button",
            style: {
              background: "rgba(255, 255, 255, 0.4)",
              borderRadius: "50%",
              border: "none",
              cursor: "pointer",
              height: 24,
              width: 24,
              position: "absolute",
              zIndex: "99",
              top: "50%",
              left: "16px",
              translate: "0 -50%",

              justifyContent: "center",
              alignItems: "center",
            },
            children: (
              <img
                style={{ width: "16px", height: "16px" }}
                alt="Backward"
                src="/static/chevron-black-left.svg"
              />
            ),
          }}
          speed={400}
          easing="linear"
        >
          {photos?.map((ph: any, indexItem: any) => (
            <div
              key={`${indexItem}ph?.documentName`}
              style={{ position: "relative" }}
            >
              <RenderMedia
                key={`${indexItem}ph?.documentName`}
                media={ph}
                isVideo={isVideo(ph?.documentName)}
                id={id}
                indexItem={indexItem}
                activeSlideIndex={activeSlideIndex}
                loadAll={load}
                thumbnail={isVideo(ph?.documentName) ? item.thumbnail : ""}
              />
            </div>
          ))}
        </ReactSimplyCarousel>
      ) : photos?.length === 1 ? (
        <>
          <div className="individual-image-video-block">
            <div className="individual-image-video-block-2">
              <RenderMedia
                media={photos[0]}
                isVideo={isVideo(photos[0]?.documentName ? photos[0] : photos[0]?.documentFileType)}
                id={id}
                thumbnail={item.thumbnail}
              />
            </div>
          </div>
        </>
      ) : isVideo(media?.uploadDocumentThumbnail) ? (
        <>
          <div className="individual-image-video-block">
            <div className="individual-image-video-block-2">
              <RenderMedia
                media={media}
                isVideo={true}
                id={id}
                thumbnail={item.thumbnail}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="individual-image-video-block">
            <div className="individual-image-video-block-2">
              <RenderMedia media={media} isVideo={false} id={id} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(EventImageCarousel);
