import React, { useContext, useEffect, useState } from "react";
import Button from "./Button";
import Interest from "./InterestCard";
import Favourites from "./Favourite";
import Location from "./Location";
import NameAge from "./NameAge";
import RoleCard from "./RoleCard";
import {
  CONNCECTION_STATUS_CHOICES,
  CONNECTION_TYPE,
  CONNECTIONS_STATUS,
  shortenName,
} from "../ConnectConstants";
import {
  makeDeleteCall,
  makePatchCall,
  makePostCall,
} from "../../../utils/Requests";
import { showToast } from "../../../utils";
import IconPlus from "../../../assets/img/connectIcons/plus-circle.svg";
import IconArrowDown from "../../../assets/img/connectIcons/arrow-down.svg";
import { useNavigate } from "react-router-dom";
import { connectContext } from "./ConectWrapper";
import { MUI_ICONS } from "../../../assets/materialIcons";
import { USER_TYPE } from "../../../types";
import { useAppDispatch } from "../../../store/store";
import { setSelectedUserId } from "../../../store/connect/connectSlice";

interface ConnectUserCardPropsI {
  connectionData: any;
  mode?: any;
  setIsFavouriteClicked?: React.Dispatch<React.SetStateAction<boolean>>;
  isScrollable: boolean;
  handleUnfavouriteClick?:any
}

const ConnectUserCard: React.FC<ConnectUserCardPropsI> = ({
  connectionData,
  mode = CONNECTION_TYPE.SUGGESTIONS,
  setIsFavouriteClicked,
  isScrollable = false,
  handleUnfavouriteClick
}) => {
  const [connectionStatus, setConnectionStatus] = useState(
    connectionData?.connectionStatus?.status
  );
  const navigate = useNavigate();
  const [isBtnClicked, setIsBtnClicked] = useState({
    accepted: false,
    rejected: false,
  });
  const {setIsProfileUpdated } = useContext(connectContext);
  const [buttonTitle, setButtonTitle] = useState(CONNECTIONS_STATUS.CONNECT);
  const [pendingRequestId, setPendingRequestId] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [connectionDetail, setConnectionDetail] = useState<any>({});
  const [showRemoveConfirmationModal, setShowRemoveConfirmationModal] =
    useState(false);
  const [connectionStatusId, setConnectionStatusId] = useState("");
  const dispatch = useAppDispatch(); 
  const handleConnectClick = (connectId: any) => {
    makePostCall({
      url: `auth/${connectId}/connections/`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          if (res.data?.status === CONNCECTION_STATUS_CHOICES.PENDING) {
            showToast(res.status.message, "success");
            setConnectionStatus(CONNECTIONS_STATUS.PENDING);
            setButtonTitle(CONNECTIONS_STATUS.PENDING);
            setPendingRequestId(res?.data?.id);
          }
        } else {
          showToast(res.status.message || res.error.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };
  const handleStoreDispatch = () => {
    dispatch(setSelectedUserId(connectionData?.id));
  };
  const handleRejectClick = (connectId: any) => {
    makeDeleteCall({
      url: `auth/connections/${connectId}/`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          setButtonTitle(CONNECTIONS_STATUS.CONNECT);
          setConnectionStatus(CONNECTIONS_STATUS.CONNECT);
          setIsBtnClicked((prev) => ({ ...prev, rejected: true }));
          setIsProfileUpdated(true);
          handleStoreDispatch()
        } else {
          showToast(res.status.message || res.error.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const handlePendingClick = (isConfirmed = false) => {
    if (!isConfirmed) {
      setShowConfirmationModal(true);
    } else {
      setShowConfirmationModal(false);
      makeDeleteCall({
        url: `auth/connections/${pendingRequestId}/`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            showToast(res.status.message, "success");
            setButtonTitle(CONNECTIONS_STATUS.CONNECT);
            setConnectionStatus(CONNECTIONS_STATUS.CONNECT);
          } else {
            showToast(res.status.message || res.error.message, "error");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };
  const handleRemoveClick = (connectId: any, isConfirmed = false) => {
    if (!isConfirmed) {
      setConnectionStatusId(connectId);
      setShowRemoveConfirmationModal(true);
    } else {
      setShowRemoveConfirmationModal(false);
      makeDeleteCall({
        url: `auth/connections/${connectionStatusId}/`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            showToast("Connection has been removed.", "success");
            setButtonTitle(CONNECTIONS_STATUS.CONNECT);
            setConnectionStatus(CONNECTIONS_STATUS.CONNECT);
            setIsProfileUpdated(true);
          } else {
            showToast(res.status.message || res.error.message, "error");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };
  const handleAcceptClick = (connectId: any) => {
    makePatchCall({
      url: `auth/connections/${connectId}/`,
      apiPayload: { status: "A" },
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          setButtonTitle(CONNECTIONS_STATUS.CONNECTED);
          setConnectionStatus(CONNECTIONS_STATUS.CONNECTED);
          setIsBtnClicked((prev) => ({ ...prev, accepted: true }));
          setIsProfileUpdated(true);
          handleStoreDispatch()
        } else {
          showToast(res.status.message || res.error.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const getButtonTitle = () => {
    let title = CONNECTIONS_STATUS.CONNECT;
    if (mode === CONNECTION_TYPE.CONNECTION) {
      title = CONNECTIONS_STATUS.CONNECTED;
    } else if (mode === CONNECTION_TYPE.FAVOURITES) {
      if (connectionStatus === CONNECTIONS_STATUS.PENDING) {
        title = CONNECTIONS_STATUS.PENDING;
      } else if (connectionStatus === CONNECTIONS_STATUS.CONNECTED) {
        title = CONNECTIONS_STATUS.CONNECTED;
      }
    }

    return title;
  };

  const renderInterest = (interestData: any) => {
    let interest: any;
    if (interestData?.length > 3) {
      interest = interestData
        .slice(0, 3)
        .map((interest: any) => (
          <Interest interest={shortenName(interest?.title, 18)} key={interest?.id} />
        ));
      interest.push(<Interest interest={`+${interestData.length - 3}`} />);
    } else {
      interest = interestData?.map((interest: any) => (
        <Interest interest={shortenName(interest?.title, 18)} key={interest?.id} />
      ));
    }
    return interest;
  };
  useEffect(() => {
    setButtonTitle(getButtonTitle());
  }, []);

  /* 
  condition: when scrollable true then width is 295px.
  when scrollable false but less than 767px then 100% but above that 295 px
  */
  return (
    <div
      className={`connect-user-card-wrapper ${
        isScrollable ? "scrollabale-width" : "non-scrollable-width"
      }`}
      onClick={() => navigate(`/our-mates/${connectionData?.id}`)}
    >
      <div className="cover-media">
        {((connectionData?.coverPhotoVideo &&
        connectionData?.coverExtension?.includes("video") &&
        connectionData?.thumbnail) ||  (connectionData?.coverPhotoVideo &&
          ["mp4", "mov", "avi"].includes(
            connectionData?.coverPhotoVideo
              ?.split('?')[0].split('.').pop().toLowerCase()
          ))) ? (
          <video
            autoPlay
            playsInline
            loop
            muted
            src={connectionData?.coverPhotoThumbnail || connectionData?.coverPhotoVideo }
            poster={connectionData?.thumbnail || undefined }
            style={{
              borderRadius: "12px 12px 0 0",
              objectFit: "cover",
              width: "100%",
              height: "150px",
            }}
          >
            <source src={connectionData?.coverPhotoThumbnail || connectionData?.coverPhotoVideo} type="video/mp4" />
          </video>
        ) : ( connectionData?.coverExtension?.includes("image") ||
          connectionData?.coverPhotoVideo ) ? (
          <img
            src={connectionData?.coverPhotoThumbnail || connectionData?.coverPhotoVideo}
            style={{
              borderRadius: "12px 12px 0 0",
              objectFit: "cover",
              width: "100%",
              height: "150px",
            }}
            alt="cover-img"
          />
        ) : (
          <div
            style={{
              borderRadius: "12px 12px 0 0",
              width: "100%",
              height: "150px",
              backgroundColor: "antiquewhite",
            }}
          >
            {" "}
          </div>
        )}
      </div>
      <div>
        {connectionData?.profilePictureExtension?.includes("video") ? (
          <video
            autoPlay
            playsInline
            loop
            muted
            src={connectionData?.profilePictureThumbnail || connectionData?.profilePicture}
            className="profile-img-style vid"
          >
            <source src={"" || ""} type="video/mp4" />
          </video>
        ) : (
          <img
            src={
              connectionData?.profilePictureThumbnail || connectionData?.profilePicture ||  "/static/dummyimg.svg"
            }
            className="profile-img-style"
            alt="profile-img"
          />
        )}
      </div>
      {connectionData?.userType === USER_TYPE.MEMBER && (
        <MUI_ICONS.MemberOrangeTick
          sx={{
            top: "140px",
            left: "124px",
            zIndex: 1,
          }}
        />
      )}
      <div className="font_os role-text">
        <RoleCard
          role={connectionData?.userType}
          isTrainer={connectionData?.isTrainer}
          connectionData={connectionData}
        />
      </div>

      <div className="font_f mx name-wrap">
        <NameAge
          connectionName={connectionData?.name}
          connectionAge={connectionData?.age}
        />
      </div>

      <Location distance={connectionData?.distance} />
      <div className="font_os interest-wrapper mx">
        {connectionData?.interests?.length !== 0 &&
          renderInterest(connectionData?.interests)}
      </div>
      {mode === CONNECTION_TYPE.REQUESTS ? (
        <div
          className={
            !isBtnClicked.accepted && !isBtnClicked.rejected
              ? "accpt-reject-fav-wrapper"
              : "connect-fav-wrapper"
          }
        >
          {!isBtnClicked.accepted && !isBtnClicked.rejected && (
            <Favourites
              statusFav={connectionData.isMarkedFavourite}
              userId={connectionData.id}
              setIsFavouriteClicked={setIsFavouriteClicked}
              isAcceptRejct={true}
            />
          )}
          {!isBtnClicked.accepted && !isBtnClicked.rejected && (
            <Button
              className="font_f reject-btn-class"
              title="Reject"
              handleBtnClick={() =>
                handleRejectClick(connectionData.connectionStatus.connectionId)
              }
            />
          )}
          {!isBtnClicked.accepted && !isBtnClicked.rejected && (
            <Button
              className="accept-btn-class font_f"
              title="Accept"
              handleBtnClick={() =>
                handleAcceptClick(connectionData.connectionStatus.connectionId)
              }
            />
          )}

          {(isBtnClicked.accepted || isBtnClicked.rejected) && (
            <Favourites
              statusFav={connectionData.isMarkedFavourite}
              userId={connectionData.id}
              setIsFavouriteClicked={setIsFavouriteClicked}
            />
          )}
          {(isBtnClicked.accepted || isBtnClicked.rejected) && (
            <Button
              className="connect-btn-class font_f"
              title={buttonTitle}
              handleBtnClick={() => {
                if (isBtnClicked.rejected || buttonTitle === CONNECTIONS_STATUS.CONNECT) {
                  handleConnectClick(connectionData.id);
                }else if(buttonTitle === CONNECTIONS_STATUS.PENDING){
                  handlePendingClick();
                }else if(buttonTitle === CONNECTIONS_STATUS.CONNECTED){
                  handleRemoveClick(
                    connectionData.connectionStatus.connectionId
                  );
                }
              }}
              iconFront={
                buttonTitle === CONNECTIONS_STATUS.CONNECT ? IconPlus : false
              }
              iconBack={
                buttonTitle === CONNECTIONS_STATUS.CONNECTED
                  ? IconArrowDown
                  : false
              }
            />
          )}
          {/* <Button
            className="connect-btn-class"
            title="Accept"
            handleBtnClick={() =>
              handleAcceptClick(connectionData.connectionStatus.connectionId)
            }
          />
          <Button
            className="reject-btn-class"
            title="Reject"
            handleBtnClick={() =>
              handleRejectClick(connectionData.connectionStatus.connectionId)
            }
          /> */}
        </div>
      ) : (
        <div className="connect-fav-wrapper">
          <Favourites
            statusFav={connectionData.isMarkedFavourite}
            userId={connectionData.id}
            setIsFavouriteClicked={setIsFavouriteClicked}
            mode={mode}
            handleNotFav={handleUnfavouriteClick}
          />
          <Button
            className="connect-btn-class font_f"
            title={buttonTitle}
            handleBtnClick={() => {
              setConnectionDetail(connectionData);
              if (buttonTitle === "Connect") {
                handleConnectClick(connectionData.id);
              } else {
                if (
                  connectionStatus === CONNECTIONS_STATUS.CONNECTED ||
                  buttonTitle === CONNECTIONS_STATUS.CONNECTED
                ) {
                  // setConnectionDetail(connectionData);
                  handleRemoveClick(
                    connectionData.connectionStatus.connectionId
                  );
                } else if (connectionStatus === CONNECTIONS_STATUS.PENDING) {
                  handlePendingClick();
                }
              }
            }}
            iconFront={
              buttonTitle === CONNECTIONS_STATUS.CONNECT ? IconPlus : false
            }
            iconBack={
              buttonTitle === CONNECTIONS_STATUS.CONNECTED
                ? IconArrowDown
                : false
            }
          />
        </div>
      )}

      {showConfirmationModal ? (
        <div className="modal show">
          <div
            style={{
              maxWidth: "373px",
              maxHeight: "410px",
              width: "100%",
              // maxWidth: "619px",
              background: "#ffffff",
              borderRadius: "12px",
              paddingInline: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "27px",
              }}
            >
              <div>
                {connectionDetail?.profilePictureExtension?.includes(
                  "video"
                ) ? (
                  <video
                    autoPlay
                    playsInline
                    loop
                    muted
                    src={connectionDetail?.profilePicture}
                    // className="profile-img-style vid"
                    style={{
                      borderRadius: "100%",
                      objectFit: "cover",
                      border: "4px solid white",
                      height: "72px",
                      width: "72px",
                      boxShadow: "0px 4px 20px 0px #0000001f",
                    }}
                  >
                    <source src={"" || ""} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    src={
                      connectionDetail?.profilePicture
                        ? connectionDetail.profilePicture
                        : "/static/dummyimg.svg"
                    }
                    // className="profile-img-style"
                    style={{
                      borderRadius: "100%",
                      objectFit: "cover",
                      border: "4px solid white",
                      height: "72px",
                      width: "72px",
                      boxShadow: "0px 4px 20px 0px #0000001f",
                    }}
                    alt="profile-img"
                  />
                )}
              </div>
            </div>
            <div
              style={{
                marginTop: "20px",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
              className="font_f"
            >
              Request cancel !
            </div>
            <div
              style={{
                marginTop: "16px",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#6C6D6F",
                textWrap:"balance"
              }}
              className="font_os"
            >
              {`Are you sure you want to cancel this request for ${connectionDetail.name} ?`}
            </div>
            <div style={{ marginTop: "24px" }}>
              <Button
                className="confirm-btn-class font_f"
                title="Confirm"
                handleBtnClick={() => handlePendingClick(true)}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "14px",
              }}
            >
              <button
                onClick={(e) => { 
                  e.stopPropagation();
                  setShowConfirmationModal(false)}
                }
                className="font_f"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  fontSize: "18px",
                  fontWeight: "500",
                  paddingTop: "16px",
                  lineHeight: "24px",
                  paddingBottom: "16px",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {showRemoveConfirmationModal ? (
        <div className="modal show" onClick={(e) => e?.stopPropagation()}>
          <div
            style={{
              maxWidth: "373px",
              maxHeight: "410px",
              width: "100%",
              // maxWidth: "619px",
              background: "#ffffff",
              borderRadius: "12px",
              paddingInline: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "27px",
              }}
            >
              <div>
                {connectionDetail?.profilePictureExtension?.includes(
                  "video"
                ) ? (
                  <video
                    autoPlay
                    playsInline
                    loop
                    muted
                    src={connectionDetail?.profilePicture}
                    // className="profile-img-style vid"
                    style={{
                      borderRadius: "100%",
                      objectFit: "cover",
                      border: "4px solid white",
                      height: "72px",
                      width: "72px",
                      boxShadow: "0px 4px 20px 0px #0000001f",
                    }}
                  >
                    <source src={"" || ""} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    src={
                      connectionDetail?.profilePicture
                        ? connectionDetail.profilePicture
                        : "/static/dummyimg.svg"
                    }
                    // className="profile-img-style"
                    style={{
                      borderRadius: "100%",
                      objectFit: "cover",
                      border: "4px solid white",
                      height: "72px",
                      width: "72px",
                      boxShadow: "0px 4px 20px 0px #0000001f",
                    }}
                    alt="profile-img"
                  />
                )}
              </div>
            </div>
            <div
              style={{
                marginTop: "20px",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
              className="font_f"
            >
              Remove connection
            </div>
            <div
              style={{
                marginTop: "16px",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#6C6D6F",
              }}
              className="font_os"
            >
              {`Are you sure you want to remove ${connectionDetail.name} from your connection?`}
            </div>
            <div style={{ marginTop: "24px" }}>
              <Button
                className="confirm-btn-class font_f"
                title="Confirm"
                handleBtnClick={() =>
                  handleRemoveClick(connectionStatusId, true)
                }
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "14px",
              }}
            >
              <button
                onClick={(e) =>{
                  e?.stopPropagation();
                  setShowRemoveConfirmationModal(false)}}
                  className="font_f"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  fontSize: "18px",
                  fontWeight: "500",
                  paddingTop: "16px",
                  lineHeight: "24px",
                  paddingBottom: "16px",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ConnectUserCard;
