import { useEffect, useState } from "react";
import moment from "moment";
import CardView from "../../../assets/img/icons/CardView";
import ListView from "../../../assets/img/icons/ListView";
import Button from "../../../global/Button";
import {
  getItemFromStore,
  removeLocalStorage,
  setLocalStorage,
  showToast,
} from "../../../utils";
import useDebounce from "../../../utils/useDebounce";
import {
  makeDeleteCall,
  makeGetCall,
  makePatchCall,
  makePostCall,
} from "../../../utils/Requests";
import apiConfig from "../../../utils/endpoints";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import endpoints from "../../../utils/endpoints";
import AddInterestPopup from "./../AddInterestPopup";
import DashboardWrapper from "../../Dashboard/DashboardWrapper";
import { apiConstants } from "../../../utils/Constants";
import ConfirmStripePopup from "../../Dashboard/CreateListing.tsx/ConfirmStripePopup";
import AddBankAccountPopup from "../../Dashboard/CreateListing.tsx/AddBankAccountPopup";
import WaitingStripeNewAccountPopup from "../../Dashboard/CreateListing.tsx/WaitingStripeNewAccountPopup";
import { ListingFormDefaultValue } from "../../../utils/FormConfig";
import { useForm } from "react-hook-form";
import LoadingLayer from "../../ConnectUserProfile/LoaderLayer";

interface ListingData {
  [key: string]: any;
}

const BankAccountStripe = () => {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: ListingFormDefaultValue,
    mode: "onChange",
  });

  const [activeTab, setActiveTab] = useState<any>("Bank Account");
  const tabs = ["Bank Account", "Through Stripe"];
  const [isPaymentOpen, setIsPaymentOpen] = useState<any>(false);
  const [paymentMode, setPaymentMode] = useState<string>(null);
  const [confirmStripeModal, setConfirmStripeModal] = useState(false);
  const [bankDetailsModal, setBankDetailsModal] = useState(false);
  const [waitingNewStripeAccountModal, setWaitingNewStripeAccountModal] =
    useState(false);
  const [listingType, setListingType] = useState("");
  const [method, setMethod] = useState("");
  const [listingData, setListingData] = useState<ListingData>({});
  const [selectedData, setSelectedData] = useState<ListingData>({});
  const [stripeAccount, setStripeAccount] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [editAccountId, setEditAccountId] = useState(null);
  const [deleteAccountId, setDeleteAccountId] = useState(null);
  const [isLoadingEffect, setIsLoadingEffect] = useState(false);

  const handleExisting = async (type) => {
    setListingType(type);
    setConfirmStripeModal(false);
  };

  const handleConnectStripe = () => {
    setIsLoadingEffect(true);
    makePostCall({ url: endpoints.connect_stripe })
      .then((res) => {
        if (res?.status.code === 200) {
          window.open(res?.data, "_blank", "rel=noopener noreferrer");
          showToast(res.status.message, "success");
        }
        setTimeout(() => {
          setIsLoadingEffect(false);
        }, 0);
      })
      .catch((err) => {
        showToast(err?.status?.message, "error");
        setTimeout(() => {
          setIsLoadingEffect(false);
        }, 0);
      });
  };

  const handleNew = async (type) => {
    setConfirmStripeModal(false);
    if (type === "bank") {
      setValue(apiConstants.account_number, "");
      setValue(apiConstants.bsb_number, "");
      setValue(apiConstants.bank_name, "");
      setBankDetailsModal(true);
    } else {
      handleConnectStripe();
      setWaitingNewStripeAccountModal(true);
    }
  };

  const handleBankDetails = () => {
    setIsLoadingEffect(true);
    let reqData: {
      [key: string]: any;
    } = {};
    reqData.direct_bank_account_name = watch(apiConstants.bank_name);
    reqData.direct_bank_bsb_number = watch(apiConstants.bsb_number);
    reqData.direct_bank_account_number = watch(apiConstants.account_number);

    if (editAccountId) {
      makePatchCall({
        url: apiConfig.saved_payments + editAccountId,
        apiPayload: reqData,
        content_type: "application/json",
      })
        .then((res) => {
          if (res.status.code === 200) {
            setIsPaymentOpen(false);
            setListingData(res?.data);
            getSavedPayment();
            setBankDetailsModal(false);
            setEditAccountId(null);
            reset();
            setIsLoadingEffect(false);
            showToast(res.status.message, "success", "1");
          }else if(res.status.code === 400){
            setIsPaymentOpen(false);
            setListingData(res?.data);
            getSavedPayment();
            setBankDetailsModal(false);
            setEditAccountId(null);
            reset();
            setIsLoadingEffect(false);
            const [firstObject= null ] = Object.keys(res?.errors || {})
            if(firstObject){
              showToast(res?.errors[firstObject][0], "error", "2");
            }else{
              showToast(res?.status?.message, "error", "2");

            }

          }
        })
        .catch((err) => {
          showToast(err, "error");
          setIsLoadingEffect(false);
        });
    } else {
      makePostCall({
        url: apiConfig.saved_payments,
        apiPayload: reqData,
        content_type: "application/json",
      })
        .then((res) => {
          if (res.status.code === 200) {
            setIsPaymentOpen(false);
            setListingData(res?.data);
            getSavedPayment();
            setBankDetailsModal(false);
            reset();
            setIsLoadingEffect(false);
            showToast("Bank account added successfully.", "success", "2");
          }
        })
        .catch((err) => {
          setIsLoadingEffect(false);
          showToast(err, "error");
        });
    }
  };

  const handleDeleteBank = () => {
    if (deleteAccountId) {
      makeDeleteCall({
        url: apiConfig.saved_payments + deleteAccountId + "?direct_bank=True",
      })
        .then((res) => {
          if (res.status.code === 200) {
            setListingData(res?.data);
            getSavedPayment();
            setBankDetailsModal(false);
            setDeleteAccountId(null);
            showToast(res.status.message, "success");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  useEffect(() => {
    handleDeleteBank();
  }, [deleteAccountId]);

  const getSavedPayment = () => {
    makeGetCall({ url: apiConfig.saved_payments })
      .then((res) => {
        if (res.status.code === 200) {
          setListingData(res?.data);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  useEffect(() => {
    getSavedPayment();
  }, []);

  const handleStripeSelectContinue = () => {
    setValue(apiConstants.stripe_account_id, selectedData?.id);
    setValue(apiConstants.existing_or_new, "E");
    setValue(apiConstants.payment_mode, "Through Stripe");
    setIsPaymentOpen(false);
  };

  const handleNewStripeAccount = (id: any) => {
    setValue(apiConstants.stripe_account_id, id);
    setValue(apiConstants.existing_or_new, "E");
    setValue(apiConstants.payment_mode, "Through Stripe");
    setIsPaymentOpen(false);
  };

  const NoBankListing = () => {
    return (
      <div className="no_listing">
        <div>
          <img src="/static/cardWhite.svg" alt="" />
        </div>
        <div className="no_listing_desc">
          No bank details added here, <br /> click on add button
        </div>
        <div>
          <Button
            btnTxt="Add"
            className={"stripe_acc_add"}
            onClick={() => setBankDetailsModal(true)}
          />
        </div>
      </div>
    );
  };

  const NoStripAccount = () => {
    return (
      <div className="no_listing">
        <div>
          <img src="/static/cardWhite.svg" alt="" />
        </div>

        <div className="no_listing_desc">
          No stripe accounts added here, <br /> click on add button
        </div>
        <div>
          <Button
            btnTxt="Add"
            className={"stripe_acc_add"}
            onClick={handleConnectStripe}
          />
        </div>
      </div>
    );
  };

  const StripeListingCard = ({ data, index }) => {
    return (
      <div
        className="acc_wrapper"
        onClick={() => {
          setStripeAccount(index);
          setSelectedData(data);
        }}
      >
        <div className="acc_content">
          <div className="acc_number">
            Stripe number: {data?.stripeAccountId}
          </div>
          <div className="acc_added">
            Added on: {moment(data?.created_at).format("Do MMMM YYYY")}
          </div>
        </div>
        <div style={{ display: "flex", gap: "12px" }}>
          <span>
            <img src="/static/EditBlack.svg" alt="" />
          </span>
          <span>
            <img src="/static/DeleteRed.svg" alt="" />
          </span>
        </div>
      </div>
    );
  };

  const BankListingCard = ({ data, index }) => {
    return (
      <div
        className="acc_wrapper"
        onClick={() => {
          setStripeAccount(index);
          setSelectedData(data);
        }}
      >
        <div className="acc_content">
          <div className="acc_number">
            Account Number: {data?.directBankAccountNumber}
          </div>
          <div className="acc_added">
            BSB Number: {data?.directBankBsbNumber}
          </div>
          <div className="acc_added">
            Account Name: {data?.directBankAccountName}
          </div>
        </div>
        <div style={{ display: "flex", gap: "12px" }}>
          <span>
            <img
              src="/static/EditBlack.svg"
              alt=""
              onClick={() => {
                setIsEditMode(true);
                setBankDetailsModal(true);
                setEditAccountId(data?.id);
                setValue("bNumber", data?.directBankBsbNumber);
                setValue("aNumber", data?.directBankAccountNumber);
                setValue("bName", data?.directBankAccountName);
              }}
            />
          </span>
          <span
            onClick={() => {
              setDeleteAccountId(data.id);
              handleDeleteBank();
            }}
          >
            <img src="/static/DeleteRed.svg" alt="" />
          </span>
        </div>
      </div>
    );
  };

  return (
    <DashboardWrapper showTab={true}>
      {isLoadingEffect && <LoadingLayer />}
      <div className="personal-details-wrapper">
        <div className="stripe-bankaccount">
          <div className="page-title">Bank account & stripe</div>
          <div className="tabs-container-wrap">
            <div className="tabs-container">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  className={`tab-button ${activeTab === tab ? "active" : ""}`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab.toUpperCase()}
                </button>
              ))}
            </div>
            {activeTab === "Bank Account" ? (
              <div className="container_stripe_payment_listing">
                {listingData?.directBankDetails?.length > 0 ? (
                  <div
                    className="bank-stripe-btn"
                    onClick={() => setBankDetailsModal(true)}
                  >
                    <img alt="" src="/static/plusorange.svg" /> Add New Bank
                    Account
                  </div>
                ) : null}
                <div className="height_listing bank_transfer">
                  {listingData?.directBankDetails?.length > 0 ? (
                    <div className="stripe_acc_listing">
                      {listingData?.directBankDetails?.map((data, index) => (
                        <BankListingCard data={data} index={index} />
                      ))}
                    </div>
                  ) : (
                    <>
                      <NoBankListing />
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="container_stripe_payment_listing">
                {listingData?.stripeDetails?.length > 0 ? (
                  <div
                    className="bank-stripe-btn"
                    onClick={handleConnectStripe}
                  >
                    <img alt="" src="/static/plusorange.svg" /> Create new
                    stripe number
                  </div>
                ) : null}
                <div className="height_listing">
                  {listingData?.stripeDetails?.length > 0 ? (
                    <div className="stripe_acc_listing">
                      {listingData?.stripeDetails?.map((data, index) => (
                        <StripeListingCard data={data} index={index} />
                      ))}
                    </div>
                  ) : (
                    <NoStripAccount />
                  )}
                </div>
                {listingData?.stripeDetails?.length > 0 ? (
                  <div className="acc_btn_container">
                    <Button
                      btnTxt="Continue"
                      className={"stripe_acc_continue"}
                      disabled={Object.keys(selectedData).length < 1}
                      onClick={handleStripeSelectContinue}
                    />

                    <Button
                      btnTxt="Didn’t find account, create new?"
                      className={"stripe_acc_new"}
                      onClick={handleConnectStripe}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {confirmStripeModal && (
        <ConfirmStripePopup
          onClose={() => setConfirmStripeModal(false)}
          handleExisting={handleExisting}
          handleNew={handleNew}
          method={method}
        />
      )}
      {bankDetailsModal && (
        <AddBankAccountPopup
          onClose={() => {
            setIsEditMode(false);
            reset();
            setBankDetailsModal(false);
          }}
          handleBankDetails={handleBankDetails}
          control={control}
          errors={errors}
          setValue={setValue}
          isEditMode={isEditMode}
          watch={watch}
        />
      )}

      {waitingNewStripeAccountModal && (
        <WaitingStripeNewAccountPopup
          onClose={() => setWaitingNewStripeAccountModal(false)}
          handleNewStripeAccount={handleNewStripeAccount}
        />
      )}
    </DashboardWrapper>
  );
};

export default BankAccountStripe;
