import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VerifiedIcon from "@mui/icons-material/Verified";

import SvgIcon from "@mui/material/SvgIcon";

const CustomVerifiedIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <path
        d="M6 0.742188L7.38084 1.7495L9.09027 1.74634L9.61521 3.37296L11 4.37501L10.4687 5.99953L11 7.62405L9.61521 8.62611L9.09027 10.2527L7.38084 10.2496L6 11.2569L4.61916 10.2496L2.90973 10.2527L2.38479 8.62611L1 7.62405L1.53125 5.99953L1 4.37501L2.38479 3.37296L2.90973 1.74634L4.61916 1.7495L6 0.742188Z"
        fill="#EE7830"
        stroke="#EE7830"
        strokeWidth="1.31434"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16016 5.99988L5.47449 7.31422L8.10317 4.68555"
        stroke="white"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};

export const MUI_ICONS = {
  Add: ({ active = false, size = "24px", ...others }) => (
    <AddIcon
      style={{
        color: active ? "#EE7830" : "#25272D",
        fontSize: size,
      }}
      {...others}
    />
  ),
  ArrowForward: ({ active = false, size = "24px", ...others }) => (
    <ArrowForwardIosIcon
      style={{
        color: active ? "#EE7830" : "#6C6D6F",
        fontSize: size,
      }}
      {...others}
    />
  ),
  MemberOrangeTick: ({ sx = {} }) => (
    <CustomVerifiedIcon
    sx={{
      position: "absolute",
      bottom: "8px",
      right: "8px",
      // color: "#EE7830",
      fontSize: "30px",
      animation: "fadeInOut 1s infinite alternate",
      "@keyframes fadeInOut": {
        from: { scale: 0.5 },
        to: { scale: 1 },
      },
      ...sx,
    }}
  />
  ),
};
