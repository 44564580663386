import { Box, Stack } from "@mui/material";
import React from "react";

import userBanner from "../../../assets/bannerImage/userBanner.png";
import micBanner from "../../../assets/bannerImage/micBanner.png";
import PrimaryButton from "../../Button/PrimaryButton";
import SecondaryButton from "../../Button/SecondaryButton";

type Props = {};

const DashboardBanner = (props: Props) => {
  return (
    <Box
      pt={{ xs: 1.25, sm: 2.5, }}
      // pb={{ xs: 1.25, sm: 2.5, }}
      maxWidth="1258px"
      mx="auto"
      px={{
        xs: 1.25,
        sm: 2.5,
        lg: 0,
      }}
      sx={{ overflow: "hidden" }}
    >
      <Stack
        width="100%"
        height={{ sm: "100%", lg: "520px" }}
        flexDirection={{ xs: "column-reverse", md: "row", lg: "row" }}
        gap={{ xs: 3.5, sm: 2, lg: 4 }}
        justifyContent="space-between"
        bgcolor="#25272D"
        borderRadius="20px"
        px={{ xs: 1, sm: 2.5, md: 3, lg: 5 }}
      >
        {/* Left Section */}
        <Box
          flex={1}
          display="flex"
          flexDirection={{ xs: "column", lg: "column" }}
          justifyContent="space-between"
          mb={{ xs: 4.5, md: 10 }}
          pt={{ xs: 2.5 }}
          mt="auto"
          px={{ xs: 1.5, sm: 2.5, lg: 0 }}
          gap={{ xs: 2.5, md: 4, lg: 10.5 }}
        >
          <Box
            maxWidth={{ xs: "100%", md: "590px" }}
            width={{ xs: "100%", lg: "590px" }}
            height={{ xs: "100%", sm: "180px" }}
          >
            <img
              src={micBanner}
              alt="mic_banner"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "scale-down",
              }}
            />
          </Box>

          <Stack color="#fff" gap={{ xs: 1.25, sm: 2.25 }}>
            <Box
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 400,
                fontSize: { xs: "14px", sm: "20px", lg: "24px" },
                lineHeight: "32px",
                letterSpacing: "0%",
              }}
            >
              Book activities. Match interests. Find mates.
            </Box>

            <Stack
              gap={{ xs: 1.25, sm: 3 }}
              direction={{ xs: "column", lg: "row" }}
            >
              <PrimaryButton
                sx={{ padding: "13px 20px" }}
                onClick={() =>
                  window.open(
                    "https://discover.fitnessmates.com/earn-money-as-lister/"
                  )
                }
              >
                Earn money as a lister
              </PrimaryButton>
              <SecondaryButton
                sx={{
                  padding: "13px 20px",
                  color: "#EE7830",
                }}
                onClick={() =>
                  window.open("https://discover.fitnessmates.com/how-it-works/")
                }
              >
                Browse & book activities
              </SecondaryButton>
            </Stack>
          </Stack>
        </Box>

        {/* Right Section */}
        <Box
          width={{ xs: "100%", md: "522px", lg: "546px" }}
          height={{ xs: "100%", md: "450px", lg: "470px" }}
          mt="auto"
          pt={{ xs: 2.5, }}
          mb={{ xs: 0, sm: 2.5 }}
        >
          <img
            src={userBanner}
            alt="fm_user"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "scale-down",
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default DashboardBanner;
