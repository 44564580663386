import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import IconMenuExpand from "../../../assets/img/connectIcons/cheron.png";
import IconToggleOn from "../../../assets/img/connectIcons/collapse-on.png";
import IconToggleOff from "../../../assets/img/connectIcons/collapse-off.png";
import IconDot from "../../../assets/img/connectIcons/icon-dot.png";
import { connectSidebarData, FITNESSMATES_REQUEST } from "../ConnectConstants";
import { getTypeOfUser } from "../../../utils";
import { MUI_ICONS } from "../../../assets/materialIcons";
import { USER_TYPE } from "../../../types";

const ConnectSidebar = ({
  connectUserProfile,
  isCollapsed,
  setIsCollapsed,
}) => {
  const [pathName, setPathName] = useState("");
  const location = useLocation();
  const handleToggle = () => {
    setIsCollapsed((prev: boolean) => !prev);
  };

  const renderPendingRequestCount = () => {
    return (
      <div className="request-count">
        <span className="count-number">
          {connectUserProfile?.pendingConnectionRequests}
        </span>
      </div>
    );
  };

  function truncateString(str = "", length) {
    if (str?.length > length) {
      return str.substring(0, length) + "...";
    } else {
      return str;
    }
  }

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  return !isCollapsed ? (
    <div className="sidebar">
      <button className="sidebar-toggle" onClick={handleToggle}>
        <img
          height="28px"
          width="28px"
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "0px",
            right: "1px",
            zIndex: "1",
          }}
          src={IconToggleOff}
          alt="toggle-icon"
        />
      </button>
      <div className="connect-user-wrapper">
        {(connectUserProfile?.coverExtension &&
          connectUserProfile?.coverExtension?.includes("video")) ||
        (connectUserProfile?.coverPhotoVideo &&
          ["mp4", "mov", "avi"].includes(
            connectUserProfile?.coverPhotoVideo
              ?.split(".")
              .splice(-1)[0]
              .toLowerCase()
          )) ? (
          <video
            autoPlay
            playsInline
            loop
            muted
            src={connectUserProfile?.coverPhotoThumbnail || connectUserProfile?.coverPhotoVideo}
            style={{
              borderRadius: "12px 12px 0 0",
              objectFit: "cover",
              width: "351px",
              height: "146px",
              filter: "blur(1px)",
            }}
          >
            <source src={connectUserProfile?.coverPhotoThumbnail || connectUserProfile?.coverPhotoVideo} type="video/mp4" />
          </video>
        ) : connectUserProfile?.coverPhotoVideo ? (
          <img
            src={connectUserProfile?.coverPhotoThumbnail || connectUserProfile?.coverPhotoVideo}
            style={{
              borderRadius: "12px 12px 0 0",
              objectFit: "cover",
              width: "351px",
              height: "146px",
              filter: "blur(1px)",
            }}
            alt="cover-img"
          />
        ) : (
          <div
            style={{
              borderRadius: "12px 12px 0 0",
              objectFit: "cover",
              width: "350px",
              height: "146px",
              backgroundColor:"antiquewhite"
            }}
          ></div>
        )}
        <div className="profile-wraper">
          <div className="connect-user-profile">
            <div
              style={{
                position: "relative",
              }}
            >
              {connectUserProfile?.profilePictureExtension?.includes(
                "video"
              ) ? (
                <video
                  autoPlay
                  playsInline
                  loop
                  muted
                  src={
                    connectUserProfile?.profilePictureThumbnail ||
                    connectUserProfile?.profilePicture
                  }
                  className="profile-img-style vid"
                >
                  <source
                    src={
                      connectUserProfile?.profilePictureThumbnail ||
                      connectUserProfile?.profilePicture
                    }
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img
                  src={
                    connectUserProfile?.profilePictureThumbnail ||
                    connectUserProfile?.profilePicture ||
                    "/static/dummyimg.svg"
                  }
                  className="profile-img-style"
                  alt="profile-img"
                />
              )}
              {connectUserProfile?.userType === USER_TYPE.MEMBER && (
                <MUI_ICONS.MemberOrangeTick
                  sx={{
                    position: "absolute",
                    bottom: "0px",
                    right: "0px",
                    color: "#EE7830",
                    fontSize: "20px",
                    animation: "fadeInOut 1s infinite alternate",
                    "@keyframes fadeInOut": {
                      from: { scale: 0.5 },
                      to: { scale: 1 },
                    },
                  }}
                />
              )}
            </div>

            <div className="user-detail-wrapper">
              <div className="user-name font_f">
                {truncateString(connectUserProfile?.name, 16)}
              </div>
              <div className="role-wrapper">
                <div className="user-role">
                  <div
                    style={{
                      marginLeft: "8px",
                      height: "3px",
                      width: "3px",
                      borderRadius: "100%",
                      border: "none",
                    }}
                  >
                    <img src={IconDot} width="3px" height="3px" />
                  </div>
                  <span style={{ marginLeft: "6px" }}>
                    {getTypeOfUser(connectUserProfile?.userType, connectUserProfile?.isIndividual, connectUserProfile?.isTrainer, connectUserProfile?.isBusiness)}
                    {/* {connectUserProfile?.isTrainer
                      ? "Trainer"
                      : connectUserProfile?.userType} */}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="connect-user-connections font_f">
            <span>
              {" "}
              {`${connectUserProfile?.totalConnections} Connections`}
            </span>
            <span className="connect-events">{`${connectUserProfile?.eventsOrganized} Events Organised`}</span>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="menu-list">
            {connectSidebarData?.map((data) => {
              return (
                <div
                  key={data.title}
                  className="item-wrapper"
                  style={
                    pathName === data.path
                      ? { backgroundColor: "antiquewhite" }
                      : { backgroundColor: "white" }
                  }
                >
                  <Link
                    className={pathName === data.path ? "active" : ""}
                    to={data.path}
                    key={data.title}
                  >
                    <div className="menu-item">
                      <div>
                        <div className="first-item">
                          <div
                            className="highlight"
                            style={
                              pathName === data.path
                                ? { backgroundColor: "#ee762f" }
                                : { backgroundColor: "white" }
                            }
                          ></div>
                          <div style={{ position: "relative" }}>
                            <img
                              src={
                                pathName === data.path
                                  ? data.activeIcon
                                  : data.icon
                              }
                              className="icon-sidebar"
                              alt="menu-icon"
                            />
                            {data.title === FITNESSMATES_REQUEST &&
                              connectUserProfile?.pendingConnectionRequests !==
                                0 &&
                              renderPendingRequestCount()}
                          </div>
                          <span className="menu-title font_f">{data.title}</span>
                        </div>
                      </div>

                      <span>
                        {!(pathName === data.path) && (
                          <img
                            src={IconMenuExpand}
                            height="24px"
                            width="24px"
                            alt=""
                          />
                        )}
                      </span>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="collapsed-sidebar">
      <button className="collapsed-toggle" onClick={handleToggle}>
        <img
          height="28px"
          width="28px"
          src={IconToggleOn}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "0px",
            right: "1px",
            zIndex: "1",
          }}
          alt="toggle-icon"
        />
      </button>
      {(connectUserProfile?.coverExtension &&
        connectUserProfile?.coverExtension?.includes("video")) ||
      (connectUserProfile?.coverPhotoVideo &&
        ["mp4", "mov", "avi"].includes(
          connectUserProfile?.coverPhotoVideo
            ?.split(".")
            .splice(-1)[0]
            .toLowerCase()
        )) ? (
        <video
          autoPlay
          playsInline
          loop
          muted
          src={ connectUserProfile?.coverPhotoThumbnail || connectUserProfile?.coverPhotoVideo }
          width="96px"
          height="146px"
          style={{
            borderRadius: "12px 12px 0 0",
            objectFit: "cover",
            filter: "blur(1px)",
          }}
        >
          <source src={connectUserProfile?.coverPhotoThumbnail || connectUserProfile?.coverPhotoVideo} type="video/mp4" />
        </video>
      ) : connectUserProfile?.coverPhotoVideo ? (
        <img
          src={connectUserProfile?.coverPhotoThumbnail || connectUserProfile?.coverPhotoVideo}
          width="96px"
          height="146px"
          style={{
            borderRadius: "12px 12px 0 0",
            objectFit: "cover",
            filter: "blur(1px)",
          }}
          alt=""
        />
      ) : (
        <div
          style={{
            borderRadius: "12px 12px 0 0",
            objectFit: "cover",
            width: "96px",
            height: "146px",
            backgroundColor: "antiquewhite",
            border: "1px solid #ee762f",
          }}
        ></div>
      )}
      <div className="collapsed-user-profile">
        <div style={{ position: "relative", top: "-120px", left: "14px" }}>
          {connectUserProfile?.profilePictureExtension?.includes("video") ? (
            <video
              className="profile-img-style vid"
              autoPlay
              playsInline
              loop
              muted
              src={connectUserProfile?.profilePictureThumbnail || connectUserProfile?.profilePicture}
            >
              <source src={connectUserProfile?.profilePictureThumbnail || connectUserProfile?.profilePicture} type="video/mp4" />
            </video>
          ) : (
            <img
              src={connectUserProfile?.profilePictureThumbnail || connectUserProfile?.profilePicture ||
                "/static/dummyimg.svg"
              }
              className="profile-img-style"
              alt=""
            />
          )}
        </div>
      </div>
      <div className="collapsed-menu-list">
        {connectSidebarData?.map((data) => {
          return (
            <div
              key={data.title}
              className="collapsed-menu-item"
              style={
                pathName === data.path
                  ? { backgroundColor: "antiquewhite" }
                  : { backgroundColor: "white" }
              }
            >
              <Link
                className={pathName === data.path ? "active" : ""}
                to={data.path}
                key={data.title}
              >
                <div style={{ position: "relative" }}>
                  <img
                    src={pathName === data.path ? data.activeIcon : data.icon}
                    className="icon-sidebar"
                    alt=""
                  />
                  {data.title === FITNESSMATES_REQUEST &&
                    connectUserProfile?.pendingConnectionRequests !== 0 &&
                    renderPendingRequestCount()}
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(ConnectSidebar);
