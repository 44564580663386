import {useEffect, useRef, useState} from "react";
import {makeGetCall, makeNextPageCall} from "../../../utils/Requests";
import {apiConstants} from "../../../utils/Constants";
import {scrollToTop, showToast} from "../../../utils";
import SearchFilter from "../ConnectComponents/SearchFilter";
import FavCard from "../ConnectComponents/FavCard";
import ConnectScrollableCard from "../ConnectComponents/ConnectScrollableCard";
import ConnectUserCard from "../ConnectComponents/ConnectUserCard";
import { isMobile, isTablet } from "react-device-detect";
import {
  CONNECTION_TYPE,
  getFilterParams,
  initialFilterData,
  SUGGESTIONS_TYPES,
} from "../ConnectConstants";
import ConnectMapContainer from "../ConnectComponents/ConnectMapContainer";
import SeeMoreDataCard from "../ConnectComponents/SeeMore";
import NoMatchedDataCard from "../ConnectComponents/NoMatchedDataCard";
import FavSeeMoreCard from "../ConnectComponents/FavSeeMoreCard";
import NoSearchedFilter from "../ConnectComponents/NoSearchedFilter";
import LoadOnScroll from "../../Reusable/LoadOnScroll";
import ConnectUserCardShimmer from "../../Reusable/Shimmer/ConnectUserCardShimmer";

const ConnectZone = () => {
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showMap, setShowMap] = useState(false);
  const [favouriteConnectionsList, setFavouriteConnectionsList] = useState([]);
  const [isFavouriteClicked, setIsFavouriteClicked] = useState(false);
  const [isSearchedSeeAllTrue, setIsSearchedSeeAllTrue] = useState(false);
  const [serachData, setSearchData] = useState<any>();
  const [userCurrentLocation, setUserCurrentLocation] = useState({
    lat: 0,
    long: 0,
  });
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [suggestionData, setSuggestionData] = useState({
    interest: [],
    near_by: [],
    trainer: [],
    other: [],
  });
  const [isDataFetching, setIsDataFetching] = useState({
    interest: true,
    near_by: true,
    trainer: true,
    other: true,
  });
  const [nextUrl, setNextUrl] = useState<any>(null);
  const [fetchingMoreData, setFetchingMoreData] = useState(false);
  const lastFetchedUrl = useRef(null);

  const handleClearClick = (mode = "search") => {
    if (mode === "search") {
      setSearch("");
    } else {
      // for clearing the filter

      setIsActiveFilter(false);
      getSuggestionsList(SUGGESTIONS_TYPES.INTEREST_BASED);
      getSuggestionsList(SUGGESTIONS_TYPES.NEAR_BY);
      getSuggestionsList(SUGGESTIONS_TYPES.TRAINER);
      getSuggestionsList(SUGGESTIONS_TYPES.OTHER);
    }
    setIsSearchedSeeAllTrue(false);
    setIsDataFetching({
      interest: true,
      near_by: true,
      trainer: true,
      other: true,
    });
  };
  const handleSuggestionSearch = (value: any) => {
    setSearch(value);
  };

  const handleFilterApply = (filterData: any) => {
    setIsSearchedSeeAllTrue(true);
    setShowFilter(true);
    setIsActiveFilter(true);
    getSearchedSuggestion("filter", filterData);
  };
  const handleSearchedSeeAllClick = () => {
    setIsSearchedSeeAllTrue(true);
    setShowFilter(false);
  };
  const handleViewToggle = () => {
    setShowMap((prev) => !prev);
  };

  const renderConnectUserCardShimmer = (length = 12) =>
    Array.from({length: length}).map((_, index) => (
      <ConnectUserCardShimmer key={"000" + index} />
    ));

  const handleLoadMore = () => {
    if (nextUrl) {
      if (nextUrl === lastFetchedUrl.current) {
        // If the nextUrl is the same as the last fetched one, return early
        return;
      }
      // Update the last fetched URL
      lastFetchedUrl.current = nextUrl;
      setFetchingMoreData(true);
      makeNextPageCall({url: nextUrl})
        .then((res) => {
          if (res?.next) {
            setNextUrl(res.next);
          } else {
            setNextUrl(null);
          }

          setSearchData((prev) => [...prev, ...res.results]);
          setFetchingMoreData(false);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  const getSuggestionsList = (
    filterBy = SUGGESTIONS_TYPES.INTEREST_BASED,
    limit = 12,
    isFilterApplied = false,
    filterParams = initialFilterData
  ) => {
    const params = {
      filter_by: filterBy,
      point: `${userCurrentLocation.long},${userCurrentLocation.lat}`,
      limit: limit,
      version:"v2"
    };
    makeGetCall({
      url: apiConstants.get_connect_suggestions,
      params: search
        ? {...params, search: search}
        : isFilterApplied
        ? {...params, ...getFilterParams(filterParams)}
        : params,
    })
      .then((res) => {
        if (res.status.code === 200) {
          const data = res.results;

          if (filterBy === SUGGESTIONS_TYPES.INTEREST_BASED) {
            setSuggestionData((prev) => ({
              ...prev,
              interest: data,
            }));
            setIsDataFetching((prev) => ({...prev, interest: false}));
          } else if (filterBy === SUGGESTIONS_TYPES.NEAR_BY) {
            setSuggestionData((prev) => ({
              ...prev,
              near_by: data,
            }));
            setIsDataFetching((prev) => ({...prev, near_by: false}));
          } else if (filterBy === SUGGESTIONS_TYPES.TRAINER) {
            setSuggestionData((prev) => ({
              ...prev,
              trainer: data,
            }));
            setIsDataFetching((prev) => ({...prev, trainer: false}));
          } else {
            setSuggestionData((prev) => ({
              ...prev,
              other: data,
            }));
            setIsDataFetching((prev) => ({...prev, other: false}));
          }
        }
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };

  const getSearchedSuggestion = (
    mode = "search",
    filterData = initialFilterData
  ) => {
    const params = {
      tab: "Users",
      search: search,
      point: `${userCurrentLocation.long},${userCurrentLocation.lat}`,
      limit: 12,
      version:"v2"
    };
    makeGetCall({
      url: apiConstants.get_connect_suggestions,
      params:
        mode === "search"
          ? {...params}
          : {...params, ...getFilterParams(filterData)},
    })
      .then((res) => {
        if (res.status.code === 200) {
          const data = res.results;
          const next = res?.next;
          if (next) {
            setNextUrl(next);
          } else {
            setNextUrl(null);
          }
          lastFetchedUrl.current = null;
          setCount(res?.count);
          setSearchData(data);
        }
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };

  const getFavouriteConnections = () => {
    makeGetCall({
      url: apiConstants.get_my_favourites,
      params:{version:"v2"}
    })
      .then((res) => {
        if (res.status.code === 200) {
          setFavouriteConnectionsList(res.results);
          setIsFavouriteClicked(false);
        }
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };

  useEffect(() => {
    if (userCurrentLocation.lat && userCurrentLocation.long) {
      getSuggestionsList(SUGGESTIONS_TYPES.INTEREST_BASED);
      getSuggestionsList(SUGGESTIONS_TYPES.NEAR_BY);
      getSuggestionsList(SUGGESTIONS_TYPES.TRAINER);
      getSuggestionsList(SUGGESTIONS_TYPES.OTHER);
    }
  }, [userCurrentLocation.lat, userCurrentLocation.long]);

  useEffect(() => {
    if (isFavouriteClicked) {
      getFavouriteConnections();
    }
  }, [isFavouriteClicked]);
  useEffect(() => {
    scrollToTop();
    // Check if Geolocation API is available in the browser
    getFavouriteConnections();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserCurrentLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (err) => {
          showToast(
            "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
            "error",
            "connect"
          );
        }
      );
    } else {
      showToast(
        "It looks like geolocation is not available in your browser.",
        "error"
      );
    }
  }, []);

  useEffect(() => {
    if (search !== "") {
      setIsSearchedSeeAllTrue(false);
      getSearchedSuggestion();
    }
    if (search === "") {
      handleClearClick();
      getSuggestionsList(SUGGESTIONS_TYPES.INTEREST_BASED);
      getSuggestionsList(SUGGESTIONS_TYPES.NEAR_BY);
      getSuggestionsList(SUGGESTIONS_TYPES.TRAINER);
      getSuggestionsList(SUGGESTIONS_TYPES.OTHER);
    }
  }, [search]);

  const renderFavConnectionsData = () => {
    let dataToReturn = favouriteConnectionsList?.map((item) => {
      return <FavCard connectionData={item} key={item.id} />;
    });

    if (isMobile && dataToReturn.length > 2) {
      dataToReturn.push(<FavSeeMoreCard />);
    } else if (isTablet && dataToReturn.length > 5) {
      dataToReturn.push(<FavSeeMoreCard />);
    } else if (dataToReturn.length > 8) {
      dataToReturn.push(<FavSeeMoreCard />);
    }

    return dataToReturn;
  };

  const renderSuggestedConnectionsData = (
    selectBy = SUGGESTIONS_TYPES.INTEREST_BASED
  ) => {
    if (suggestionData[selectBy]?.length > 0) {
      const dataToReturn = suggestionData[selectBy]?.map((suggestion: any) => {
        return (
          <div key={`${selectBy}${suggestion.id}`}>
            <ConnectUserCard
              connectionData={suggestion}
              mode={CONNECTION_TYPE.SUGGESTIONS}
              setIsFavouriteClicked={setIsFavouriteClicked}
              isScrollable={true}
              key={suggestion?.id}
            />
          </div>
        );
      });
      dataToReturn.push(<SeeMoreDataCard path="/connect/suggestions" />);
      return dataToReturn;
    } else {
      return <NoMatchedDataCard />;
    }
  };

  const [mapViewUserList, setMapViewUserList] = useState(null);

const fetchMapViewData = () => {
  const params: any = {
    version: "v2",
    map_view: 'True',
    ...(userCurrentLocation?.long && userCurrentLocation?.lat && {
      point: `${userCurrentLocation.long},${userCurrentLocation.lat}`,
    }),
  };

  makeGetCall({
    url: apiConstants.get_connect_suggestions,
    params: params,
  })
    .then((res) => {
      if (res?.status?.code === 200) {
        setMapViewUserList(res?.data || '');
      }
    })
    .catch((err: any) => {
      showToast(err, "error");
    });
};

useEffect(() => {
  if (showMap) {
    fetchMapViewData();
  }
}, [showMap]);

  

  return (
    <div style={{width: "100%"}}>
      <SearchFilter
        showMap={showMap}
        handleViewToggle={handleViewToggle}
        handleSearchChange={handleSuggestionSearch}
        serachData={serachData}
        handleSeeAll={handleSearchedSeeAllClick}
        isFilterSupported={true}
        handleFilterApply={handleFilterApply}
        handleClearSearch={handleClearClick}
        isSearchedSeeAllTrue={isSearchedSeeAllTrue}
        isActiveFilter={isActiveFilter}
      />
      { !showMap ? (
        !isSearchedSeeAllTrue ? (
          <div>
            {favouriteConnectionsList.length !== 0 && (
              <ConnectScrollableCard
                title="My favourite mates"
                description="View and manage your favourite Fitness mates connections"
                path="/connect/favourite"
                ClassName="fav-content-bloc"
                render={renderFavConnectionsData()}
                isLoading={false}
              />
            )}
            <ConnectScrollableCard
              title="Fitness mates based on your interest"
              description="Expand your network with suggested connections based on your interest"
              path="/connect/suggestions"
              ClassName="fav-content-bloc"
              render={renderSuggestedConnectionsData()}
              isLoading={isDataFetching.interest}
            />
            <ConnectScrollableCard
              title="Nearby fitness mates"
              description="Discover and connect with active individuals in your local area"
              path="/connect/suggestions"
              ClassName="fav-content-bloc"
              render={renderSuggestedConnectionsData("near_by")}
              isLoading={isDataFetching.near_by}
            />
            <ConnectScrollableCard
              title="Find fitness experts"
              description="Connect with certified trainers and nutrition specialists in your area"
              path="/connect/suggestions"
              ClassName="fav-content-bloc"
              render={renderSuggestedConnectionsData("trainer")}
              isLoading={isDataFetching.trainer}
            />
            <ConnectScrollableCard
              title="Other suggestions"
              description="Get all the fitness mates suggestion here"
              path="/connect/suggestions"
              ClassName="fav-content-bloc"
              render={renderSuggestedConnectionsData("other")}
              isLoading={isDataFetching.other}
            />
          </div>
        ) : (
          <div>
            <div
              style={{
                fontSize: "14px",
                lineHeight: "24px",
                fontWeight: "500",
                margin: "21px 0px 12px 0px",
              }}
            >{`${
              showFilter ? "Filter results" : "Search results"
            } (${count})`}</div>
            {serachData?.length > 0 ? (
              <div>
                <div
                  className="content-style"
                  // style={{ display: "flex", flexWrap: "wrap", gap: "24px" }}
                >
                  {!fetchingMoreData &&
                    serachData?.map((connection) => (
                      <ConnectUserCard
                        connectionData={connection}
                        key={connection.id}
                        mode={CONNECTION_TYPE.SUGGESTIONS}
                        isScrollable={false}
                      />
                    ))}
                  {fetchingMoreData && renderConnectUserCardShimmer()}
                  {!fetchingMoreData && nextUrl && (
                    <LoadOnScroll
                      nextUrl={nextUrl}
                      onLoadMore={handleLoadMore}
                      loaderComponent={false}
                    />
                  )}
                </div>
              </div>
            ) : (
              <NoSearchedFilter
                mode={showFilter ? "filter" : "search"}
                searchTerm={search}
              />
            )}
          </div>
        )
      ) : (
        <div className="inner-contet">
          {!isSearchedSeeAllTrue && (
            <div className="content-title">Suggestions near by you</div>
          )}
          {isSearchedSeeAllTrue && (
            <div
              style={{
                fontSize: "14px",
                lineHeight: "24px",
                fontWeight: "500",
                margin: "21px 0px 12px 0px",
              }}
            >{`${
              showFilter ? "Filter results" : "Search results"
            } (${count})`}</div>
          )}
          <ConnectMapContainer
            eventMap={
              isSearchedSeeAllTrue
                ? serachData
                : mapViewUserList || []
            }
            latitude={userCurrentLocation.lat}
            longitude={userCurrentLocation.long}
            mode={CONNECTION_TYPE.SUGGESTIONS}
          />
        </div>
      )}
    </div>
  );
};

export default ConnectZone;