import Events2 from "../components/Dashboard/Event/Events2";
import GlobalHeader2 from "../components/Dashboard/GlobalHeader2";
import GlobalFooter from "../components/Dashboard/GlobalFooter";
import DashboardBanner from "../components/DashboardEventComponent/DashboardBanner";

const EventsPage = () => {

  return (
    <>
      <GlobalHeader2 />
      <DashboardBanner />
      <Events2 />
      <GlobalFooter />
    </>
  );
};

export default EventsPage;
