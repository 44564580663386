import React, { useState, useRef } from "react";

type CustomNumberFieldProps = {
  value?: number | string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  id?: string;
  className?: string;
  inputLabel?: string;
  name: string;
  errors?: Record<string, any>;
};

const CustomNumberField: React.FC<CustomNumberFieldProps> = ({
  value = "",
  onChange,
  placeholder,
  disabled = false,
  maxLength,
  id,
  className = "",
  inputLabel,
  name,
  errors = {},
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClear = () => {
    onChange({ target: { value: "", name } } as React.ChangeEvent<HTMLInputElement>);
    if (inputRef.current) inputRef.current.value = "";
  };

  return (
    <div
      className={`form_group ${value || isFocus ? "focused" : ""} ${className}`}
    >
      <div className={errors[name] ? "input_box input_error" : "input_box"}>
        {inputLabel && <span className="input_label">{inputLabel}</span>}
        <input
          type="text"
          ref={inputRef}
          id={id}
          placeholder={isFocus ? placeholder : ""}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          disabled={disabled}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          style={{
            border: "1px solid #dcdcdc",
          }}
        />
        {value && (
          <img
            src="/static/cross-circle-gray-dark.svg"
            className={`${disabled ? "disabled" : ""}`}
            style={{
              position:'absolute',
              top:'13px',
              right:'12px',
              cursor:'pointer',
              zIndex:'2',
            }}
            alt="Clear"
            onClick={handleClear}
          />
        )}
      </div>
    </div>
  );
};

export default CustomNumberField;
